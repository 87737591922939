@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:wght@400;700&display=swap');

/* responsive breakpoints */
$small-device-breakpoint: 576px;
$medium-device-breakpoint: 821px;
$large-device-breakpoint: 1024px;
$xl-device-breakpoint: 1200px;
$two-xl-device-breakpoint: 1400px;


.hide-mobile {
  display: none !important;

  @media (min-width: $small-device-breakpoint) {
    display: initial !important;
  }
}
.hide-mobile-flex {
  display: none !important;

  @media (min-width: $medium-device-breakpoint) {
    display: flex !important;
  }
}
.show-mobile {
  display: initial !important;

  @media (min-width: $small-device-breakpoint) {
    display: none !important;
  }
}
.show-mobile-block {
  display: block !important;

  @media (min-width: $small-device-breakpoint) {
    display: none !important;
  }
}


:root {
  --jam-brand: #992063;
  --jam-background: #ffffff;
  --jam-border: #DBDBDB;
  --jam-text-primary: #171A1F;
  --jam-text-secondary: #636363;
  --jam-text-primary-reverse: #ffffff;
  --jam-positive: #17A654;
  --jam-positive-highlight: #107D3F;
  --jam-input-border: #DADADA;
  --jam-input-background: #F3F3F3;
  --jam-button-text: #ffffff;
  --jam-package-background: #ffffff;
  --jam-input-disabled: #666666;
}

@media (prefers-color-scheme: dark) {
  :root {
    --jam-brand: #992063;
    --jam-background: #171A1F;
    --jam-border: #4A4C50;
    --jam-text-primary: #ffffff;
    --jam-text-secondary: #D9D9D9;
    --jam-text-primary-reverse: #171A1F;
    --jam-positive: #17A654;
    --jam-positive-highlight: #1DD36B;
    --jam-input-border: #3B3D41;
    --jam-input-background: #23252A;
    --jam-package-background: #2C3037;
    --jam-input-disabled: #666666;
  }
}

* {
  padding: 0;
  margin: 0;
  outline: none !important;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

body {
  padding: 0;
  background: var(--jam-background);
  background-size: contain;
  color: var(--jam-text-primary);
}

header, main, footer {
  max-width: 992px;
  margin: 0 auto;
  padding: 0 22px;
}

header {
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 22px;
  padding-top: 66px;

  .logo {
    display: block;
    width: 150px;
    height: 32px;
    background: transparent url('./images/logo-lgt-mode.svg') no-repeat center center;
    background-size: 150px 32px;
    text-indent: -9999px;
    text-decoration: none;
  }

  .right {
    text-align: right;
  }

  ul {
    list-style: none;

    li {
      display: inline-block;
      padding: 0 11px;

      @media (min-width: $medium-device-breakpoint) {
        padding: 0 22px;
      }

      a {
        font-size: 16 px;
        font-weight: 500;
        text-decoration: none;
        color: var(--jam-text-primary);

        &:hover {
          color: var(--jam-brand);
        }

        &.special {
          padding: 6px 24px;
          background: var(--jam-positive);
          border-radius: 9000px;
          font-weight: 400;

          &:hover {
            background: var(--jam-positive-highlight);
            color: white;
          }
        }
      }
    }
  }
}

.herobox {
  padding: 88px 0;
  text-align: center;

  .thanks {
    p {
      margin-bottom: 6px;
      padding: 0 22px;

      @media (min-width: $medium-device-breakpoint) { 
        padding: 0;
      }
    }
  }

  .screens {
    width: 320px;
    height: 198px;
    margin: 55px auto 0 auto;
    background: transparent url('./images/screens-tiny.png') no-repeat center center;
    background-size: cover;

    @media (min-width: $medium-device-breakpoint) { 
      width: 992px;
      height: 632px;
      background-size: 992px 632px;
    }
  }
}

.callout {
  text-align: center;
}

.howItWorks {
  margin: 77px 0;

  @media (min-width: $medium-device-breakpoint) { 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 22px;
  }

  .box {
    margin-bottom: 22px;
    padding: 22px;
    border: 1px solid var(--jam-border);

    .image {
      height: 266px;
      margin-bottom: 33px;
      border: 1px solid var(--jam-border);

      &.upload {
        background: transparent url('./images/upload-tiny.png') no-repeat center center;
        background-size: cover;
      }
      &.remove {
        background: transparent url('./images/instruments-tiny.png') no-repeat center center;
        background-size: cover;
      }
      &.play {
        background: transparent url('./images/play-tiny.png') no-repeat center center;
        background-size: cover;
      }
    }
  }
}

.faq {
  margin: 77px 0;

  dl {
    margin-top: 33px;

    dt {
      width: 100%;
      margin-bottom: 11px;
      padding-top: 22px;
      border-top: 1px solid var(--jam-border);
      font-size: 22px;
      font-weight: 600;

      &:first-child {
        border-top: none;
      }

      i {
        float: right;
        display: inline-block;
        width: 32px;
        height: 32px;
        background: #ccc;
      }
    }
    dd {
      // display: none;
      margin-bottom: 22px;
      font-size: 14px;
      line-height: 24px;
      color: var(--jam-text-secondary);

      span.a {
        text-decoration: underline;
        cursor: pointer;
        
        &:hover {
          color: var(--jam-positive);
        }
      }
    }
  }
}

.pricing {
  text-align: center;

  .packages {
    max-width: 460px;
    margin: 55px auto 22px auto;

    @media (min-width: $medium-device-breakpoint) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 22px;
    }

    .package {
      margin-bottom: 22px;
      padding: 22px;
      border-radius: 22px;
      // background: var(--jam-package-background);
      border: 1px solid var(--jam-border);
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.33);
      text-align: left;

      @media (min-width: $medium-device-breakpoint) {
        margin-bottom: 0;
      }

      .subhead {
        font-size: 12px;
        font-weight: 700;
        color: var(--jam-positive);
      }
      .title {
        margin-top: 15px;
        font-size: 18px;
        font-weight: 900;
      }
      .description {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 400;
        color: var(--jam-text-secondary);
      }
      .price {
        margin: 22px 0;
        font-size: 48px;
        font-weight: 900;
        text-align: center;

        span {
          font-size: 14px;
          font-weight: 500;
          color: var(--jam-text-secondary);
        }
      }
    }
  }
}

.about {
  margin: 77px 0;

  .columns {
    margin-top: 44px;
    color: var(--jam-text-secondary);

    @media (min-width: $medium-device-breakpoint) {
      column-count: 2;
      column-gap: 66px;
    }

    p {
      margin-bottom: 22px;
      font-size: 16px;
      line-height: 26px;
    }

    a {
      color: var(--jam-text-primary);

      &:hover {
        color: var(--jam-positive);
      }
    }
  }
}

footer {
  margin: 0 auto 33px auto;
  padding-top: 33px;
  border-top: 1px solid var(--jam-border);
  text-align: center;
  font-size: 12px;
  color: var(--jam-text-secondary);

  .biscuit {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto 11px auto;
    background: transparent url('./images/biscuit.svg') no-repeat center center;
    background-size: 32px;
  }

  .links {
    margin-bottom: 24px;
    font-size: 14px;

    a {
      margin: 0 12px;
      color: var(--jam-text-primary);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: var(--jam-positive);
      }

      &.special {
        padding: 6px 24px;
        border-radius: 9000px;
        border: 1px solid var(--jam-border);

        &:hover {
          text-decoration: none;
          background: var(--jam-positive);
          color: white;
        }
      }
    }

    span {
      color: var(--jam-text-secondary);

      .a {
        color: var(--jam-text-primary);

        &:hover {
          color: var(--jam-positive);
          cursor: pointer;
        }
      }
    }
  }
  .coldstream {
    margin-bottom: 6px;
  }
}

.overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);

  .content {
    width: 320px;
    padding: 32px 24px;
    border-radius: 22px;
    background: var(--jam-background);
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.66);

    @media (min-width: $medium-device-breakpoint) {
      width: 400px;
    }

    label {
      display: block;
      margin-bottom: 22px;
      font-size: 14px;
      line-height: 18px;
      color: var(--jam-text-primary);
    }

    .inputWrapper { 
      @media (min-width: $medium-device-breakpoint) {
        display: table;
      }

      .t {
        @media (min-width: $medium-device-breakpoint) {
          display: table-cell;
          width: 100%;
          padding-right: 11px;
        }
      }
      .t > input {
        width: 100%;
      }
    }

    .copied {
      text-align: center;

      h2 {
        margin: 0 0 6px 0;
        font-size: 24px;
      }
    }

    .close {
      width: 100%;
      margin-top: 22px;
      text-align: center;
      font-size: 14px;

      span {
        &:hover {
          color: var(--jam-positive);
          cursor: pointer;
        }
      }
    }
  }
}

main.post {
  max-width: 680px;
  margin-top: 88px;
  margin-bottom: 88px;

  .attribution {
    display: grid;
    grid-template-columns: 44px 1fr;
    gap: 11px;

    .pfp {
      border: 1px solid var(--jam-border);
      border-radius: 9000px;
      height: 44px;
      background: #ccc url('./images/headshot.png') no-repeat center center;
      background-size: 44px;
    }
    .name {
      padding-top: 3px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;

      div {
        font-size: 12px;
        font-weight: 400;
        font-family: "Source Serif 4", Georgia, Cambria, "Times New Roman", Times, serif;
      }
    }
  }

  img.tweet {
    display: block;
    margin: 22px auto;
    border-radius: 22px;
    border: 1px solid var(--jam-border);
  }

  h1 {
    font-size: 42px;
    line-height: 52px;
  }

  strong {
    font-family: "Source Serif 4", Georgia, Cambria, "Times New Roman", Times, serif;
    font-weight: 700;
  }
  em {
    font-family: "Source Serif 4", Georgia, Cambria, "Times New Roman", Times, serif;
  }

  p {
    margin-top: 2.14em;
    font-family: "Source Serif 4", Georgia, Cambria, "Times New Roman", Times, serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;

    &.highlight {
      padding: 22px;
      background: var(--jam-input-background);
      // border: 1px solid var(--jam-border);
      border-radius: 22px;
      font-size: 14px;
      line-height: 26px;
    }

    a {
      color: var(--jam-positive);
      font-family: "Source Serif 4", Georgia, Cambria, "Times New Roman", Times, serif;

      &:hover {
        color: var(--jam-positive-highlight);
      }
    }

    &.footnote {
      font-size: 12px;
      line-height: 20px;
    }
  }

  .cta {
    margin: 44px 0;
    padding: 44px;
    // background: var(--jam-input-background);
    border: 1px solid var(--jam-border);
    border-radius: 22px;
    text-align: center;

    i.biscuit {
      display: block;
      width: 64px;
      height: 64px;
      margin: 0 auto 11px auto;
      background: transparent url('./images/biscuit.svg') no-repeat center center;
      background-size: 64px;
    }

    h2 {
      margin: 0 0 22px 0;
      padding-top: 0;
      font-size: 26px;
      line-height: 38px;
      color: white;
    }
    p {
      margin-top: 0;
    }

    .buttonWrap {
      margin-bottom: 0;
    }
  }
}

/* Text Styles */

h1 {
  max-width: 740px;
  margin: 0 auto 11px auto;
  font-size: 32px;
  font-weight: 900;
  line-height: 38px;

  @media (min-width: $medium-device-breakpoint) {
    font-size: 42px;
    line-height: 46px;
  }

  em {
    color: var(--jam-brand);
  }
}
h2 {
  margin-bottom: 55px;
  font-size: 18px;
  font-weight: 500;
  color: var(--jam-text-secondary);

  strong {
    color: var(--jam-text-primary);
  }
}

h3 {
  margin-bottom: 11px;
  font-size: 42px;
  font-weight: 600;
  line-height: 42px;
  text-align: center;
}

h4 {
  font-size: 18px;
  font-weight: 500;
  color: var(--jam-text-secondary);

  em {
    font-style: italic;
  }
  strong {
    color: var(--jam-text-primary);
  }
}

h5 {
  margin-bottom: 11px;
  font-size: 16px;
  font-weight: 700;
}

h6 {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: var(--jam-text-secondary);
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--jam-text-secondary);

  strong {
    color: var(--jam-text-primary);
    font-style: italic;
  }
}

input[type="email"] {
  width: 100%;
  max-width: 370px;
  margin-right: 6px;
  padding: 16px;
  border-radius: 9000px;
  border: 1px solid var(--jam-input-border);
  background: var(--jam-input-background);
  color: var(--jam-text-primary);
  font-size: 16px;
  font-weight: 400;

  &:disabled {
    color: var(--jam-input-disabled);
  }
}

.buttonWrap {
  margin: 22px 0;
}

button {
  width: 100%;
  margin-top: 11px;
  padding: 16px 26px; 
  border: none;
  border-radius: 9000px;
  background-color: var(--jam-positive);
  color: var(--jam-button-text);
  font-size: 16px;
  font-weight: 700;

  @media (min-width: $medium-device-breakpoint) {
    width: auto;
    margin-top: 0;
    font-size: 14px;
    font-weight: 500;
  }

  &.fat {
    width: 100%;
    padding: 16px 26px; 
    font-size: 16px;
    font-weight: 700;
    line-height: 42px;
  }

  &.wide {
    padding: 16px 48px;
  }

  &:hover {
    background: var(--jam-positive-highlight);
    cursor: pointer;
  }

  &:disabled, &:disabled:hover {
    min-width: 120px;
    background: var(--jam-input-background);
    cursor: default;
  }

  .loader {
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 auto;
    position: relative;
    border-radius: 50%;
    color: var(--jam-brand);
    animation: fill 1s ease-in infinite alternate;
  
    &::before , &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      left: 24px;
      top: 0;
      animation: fill 0.9s ease-in infinite alternate;
    }

    &::after {
      left: auto;
      right: 24px;
      animation-duration: 1.1s;
    }
  }
}

@keyframes fill {
 0% {  box-shadow: 0 0 0 2px inset }
 100%{ box-shadow: 0 0 0 10px inset }
}
  

@media (prefers-color-scheme: dark) {
  header {
    .logo {
      width: 150px;
      height: 32px;
      background: transparent url('./images/logo-drk-mode.svg') no-repeat center center;
      background-size: 150px 32px;
      text-indent: -9999px;
    }
  }
}