@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Source+Serif+4:wght@400;700&display=swap";
.hide-mobile {
  display: none !important;
}

@media (width >= 576px) {
  .hide-mobile {
    display: initial !important;
  }
}

.hide-mobile-flex {
  display: none !important;
}

@media (width >= 821px) {
  .hide-mobile-flex {
    display: flex !important;
  }
}

.show-mobile {
  display: initial !important;
}

@media (width >= 576px) {
  .show-mobile {
    display: none !important;
  }
}

.show-mobile-block {
  display: block !important;
}

@media (width >= 576px) {
  .show-mobile-block {
    display: none !important;
  }
}

:root {
  --jam-brand: #992063;
  --jam-background: #fff;
  --jam-border: #dbdbdb;
  --jam-text-primary: #171a1f;
  --jam-text-secondary: #636363;
  --jam-text-primary-reverse: #fff;
  --jam-positive: #17a654;
  --jam-positive-highlight: #107d3f;
  --jam-input-border: #dadada;
  --jam-input-background: #f3f3f3;
  --jam-button-text: #fff;
  --jam-package-background: #fff;
  --jam-input-disabled: #666;
}

@media (prefers-color-scheme: dark) {
  :root {
    --jam-brand: #992063;
    --jam-background: #171a1f;
    --jam-border: #4a4c50;
    --jam-text-primary: #fff;
    --jam-text-secondary: #d9d9d9;
    --jam-text-primary-reverse: #171a1f;
    --jam-positive: #17a654;
    --jam-positive-highlight: #1dd36b;
    --jam-input-border: #3b3d41;
    --jam-input-background: #23252a;
    --jam-package-background: #2c3037;
    --jam-input-disabled: #666;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
  outline: none !important;
}

body {
  background: var(--jam-background);
  color: var(--jam-text-primary);
  background-size: contain;
  padding: 0;
}

header, main, footer {
  max-width: 992px;
  margin: 0 auto;
  padding: 0 22px;
}

header {
  grid-template-columns: 150px 1fr;
  gap: 22px;
  padding-top: 66px;
  display: grid;
}

header .logo {
  text-indent: -9999px;
  background: url("logo-lgt-mode.34758a96.svg") center / 150px 32px no-repeat;
  width: 150px;
  height: 32px;
  text-decoration: none;
  display: block;
}

header .right {
  text-align: right;
}

header ul {
  list-style: none;
}

header ul li {
  padding: 0 11px;
  display: inline-block;
}

@media (width >= 821px) {
  header ul li {
    padding: 0 22px;
  }
}

header ul li a {
  font-size: 16 px;
  color: var(--jam-text-primary);
  font-weight: 500;
  text-decoration: none;
}

header ul li a:hover {
  color: var(--jam-brand);
}

header ul li a.special {
  background: var(--jam-positive);
  border-radius: 9000px;
  padding: 6px 24px;
  font-weight: 400;
}

header ul li a.special:hover {
  background: var(--jam-positive-highlight);
  color: #fff;
}

.herobox {
  text-align: center;
  padding: 88px 0;
}

.herobox .thanks p {
  margin-bottom: 6px;
  padding: 0 22px;
}

@media (width >= 821px) {
  .herobox .thanks p {
    padding: 0;
  }
}

.herobox .screens {
  background: url("screens-tiny.e6918a0b.png") center / cover no-repeat;
  width: 320px;
  height: 198px;
  margin: 55px auto 0;
}

@media (width >= 821px) {
  .herobox .screens {
    background-size: 992px 632px;
    width: 992px;
    height: 632px;
  }
}

.callout {
  text-align: center;
}

.howItWorks {
  margin: 77px 0;
}

@media (width >= 821px) {
  .howItWorks {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 22px;
    display: grid;
  }
}

.howItWorks .box {
  border: 1px solid var(--jam-border);
  margin-bottom: 22px;
  padding: 22px;
}

.howItWorks .box .image {
  border: 1px solid var(--jam-border);
  height: 266px;
  margin-bottom: 33px;
}

.howItWorks .box .image.upload {
  background: url("upload-tiny.74cad6ae.png") center / cover no-repeat;
}

.howItWorks .box .image.remove {
  background: url("instruments-tiny.e12a420e.png") center / cover no-repeat;
}

.howItWorks .box .image.play {
  background: url("play-tiny.5e32360e.png") center / cover no-repeat;
}

.faq {
  margin: 77px 0;
}

.faq dl {
  margin-top: 33px;
}

.faq dl dt {
  border-top: 1px solid var(--jam-border);
  width: 100%;
  margin-bottom: 11px;
  padding-top: 22px;
  font-size: 22px;
  font-weight: 600;
}

.faq dl dt:first-child {
  border-top: none;
}

.faq dl dt i {
  float: right;
  background: #ccc;
  width: 32px;
  height: 32px;
  display: inline-block;
}

.faq dl dd {
  color: var(--jam-text-secondary);
  margin-bottom: 22px;
  font-size: 14px;
  line-height: 24px;
}

.faq dl dd span.a {
  cursor: pointer;
  text-decoration: underline;
}

.faq dl dd span.a:hover {
  color: var(--jam-positive);
}

.pricing {
  text-align: center;
}

.pricing .packages {
  max-width: 460px;
  margin: 55px auto 22px;
}

@media (width >= 821px) {
  .pricing .packages {
    grid-template-columns: 1fr 1fr;
    gap: 22px;
    display: grid;
  }
}

.pricing .packages .package {
  border: 1px solid var(--jam-border);
  text-align: left;
  border-radius: 22px;
  margin-bottom: 22px;
  padding: 22px;
  box-shadow: 0 2px 12px #00000054;
}

@media (width >= 821px) {
  .pricing .packages .package {
    margin-bottom: 0;
  }
}

.pricing .packages .package .subhead {
  color: var(--jam-positive);
  font-size: 12px;
  font-weight: 700;
}

.pricing .packages .package .title {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 900;
}

.pricing .packages .package .description {
  color: var(--jam-text-secondary);
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
}

.pricing .packages .package .price {
  text-align: center;
  margin: 22px 0;
  font-size: 48px;
  font-weight: 900;
}

.pricing .packages .package .price span {
  color: var(--jam-text-secondary);
  font-size: 14px;
  font-weight: 500;
}

.about {
  margin: 77px 0;
}

.about .columns {
  color: var(--jam-text-secondary);
  margin-top: 44px;
}

@media (width >= 821px) {
  .about .columns {
    column-count: 2;
    column-gap: 66px;
  }
}

.about .columns p {
  margin-bottom: 22px;
  font-size: 16px;
  line-height: 26px;
}

.about .columns a {
  color: var(--jam-text-primary);
}

.about .columns a:hover {
  color: var(--jam-positive);
}

footer {
  border-top: 1px solid var(--jam-border);
  text-align: center;
  color: var(--jam-text-secondary);
  margin: 0 auto 33px;
  padding-top: 33px;
  font-size: 12px;
}

footer .biscuit {
  background: url("biscuit.b654b6a2.svg") center / 32px no-repeat;
  width: 32px;
  height: 32px;
  margin: 0 auto 11px;
  display: block;
}

footer .links {
  margin-bottom: 24px;
  font-size: 14px;
}

footer .links a {
  color: var(--jam-text-primary);
  margin: 0 12px;
  text-decoration: none;
}

footer .links a:hover {
  color: var(--jam-positive);
  text-decoration: underline;
}

footer .links a.special {
  border: 1px solid var(--jam-border);
  border-radius: 9000px;
  padding: 6px 24px;
}

footer .links a.special:hover {
  background: var(--jam-positive);
  color: #fff;
  text-decoration: none;
}

footer .links span {
  color: var(--jam-text-secondary);
}

footer .links span .a {
  color: var(--jam-text-primary);
}

footer .links span .a:hover {
  color: var(--jam-positive);
  cursor: pointer;
}

footer .coldstream {
  margin-bottom: 6px;
}

.overlay {
  z-index: 2;
  background: #000c;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay .content {
  background: var(--jam-background);
  border-radius: 22px;
  width: 320px;
  padding: 32px 24px;
  box-shadow: 0 2px 12px #000000a8;
}

@media (width >= 821px) {
  .overlay .content {
    width: 400px;
  }
}

.overlay .content label {
  color: var(--jam-text-primary);
  margin-bottom: 22px;
  font-size: 14px;
  line-height: 18px;
  display: block;
}

@media (width >= 821px) {
  .overlay .content .inputWrapper {
    display: table;
  }

  .overlay .content .inputWrapper .t {
    width: 100%;
    padding-right: 11px;
    display: table-cell;
  }
}

.overlay .content .inputWrapper .t > input {
  width: 100%;
}

.overlay .content .copied {
  text-align: center;
}

.overlay .content .copied h2 {
  margin: 0 0 6px;
  font-size: 24px;
}

.overlay .content .close {
  text-align: center;
  width: 100%;
  margin-top: 22px;
  font-size: 14px;
}

.overlay .content .close span:hover {
  color: var(--jam-positive);
  cursor: pointer;
}

main.post {
  max-width: 680px;
  margin-top: 88px;
  margin-bottom: 88px;
}

main.post .attribution {
  grid-template-columns: 44px 1fr;
  gap: 11px;
  display: grid;
}

main.post .attribution .pfp {
  border: 1px solid var(--jam-border);
  background: #ccc url("headshot.b5727a0d.png") center / 44px no-repeat;
  border-radius: 9000px;
  height: 44px;
}

main.post .attribution .name {
  padding-top: 3px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

main.post .attribution .name div {
  font-family: "Source Serif 4", Georgia, Cambria, Times New Roman, Times, serif;
  font-size: 12px;
  font-weight: 400;
}

main.post img.tweet {
  border: 1px solid var(--jam-border);
  border-radius: 22px;
  margin: 22px auto;
  display: block;
}

main.post h1 {
  font-size: 42px;
  line-height: 52px;
}

main.post strong {
  font-family: "Source Serif 4", Georgia, Cambria, Times New Roman, Times, serif;
  font-weight: 700;
}

main.post em {
  font-family: "Source Serif 4", Georgia, Cambria, Times New Roman, Times, serif;
}

main.post p {
  margin-top: 2.14em;
  font-family: "Source Serif 4", Georgia, Cambria, Times New Roman, Times, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

main.post p.highlight {
  background: var(--jam-input-background);
  border-radius: 22px;
  padding: 22px;
  font-size: 14px;
  line-height: 26px;
}

main.post p a {
  color: var(--jam-positive);
  font-family: "Source Serif 4", Georgia, Cambria, Times New Roman, Times, serif;
}

main.post p a:hover {
  color: var(--jam-positive-highlight);
}

main.post p.footnote {
  font-size: 12px;
  line-height: 20px;
}

main.post .cta {
  border: 1px solid var(--jam-border);
  text-align: center;
  border-radius: 22px;
  margin: 44px 0;
  padding: 44px;
}

main.post .cta i.biscuit {
  background: url("biscuit.b654b6a2.svg") center / 64px no-repeat;
  width: 64px;
  height: 64px;
  margin: 0 auto 11px;
  display: block;
}

main.post .cta h2 {
  color: #fff;
  margin: 0 0 22px;
  padding-top: 0;
  font-size: 26px;
  line-height: 38px;
}

main.post .cta p {
  margin-top: 0;
}

main.post .cta .buttonWrap {
  margin-bottom: 0;
}

h1 {
  max-width: 740px;
  margin: 0 auto 11px;
  font-size: 32px;
  font-weight: 900;
  line-height: 38px;
}

@media (width >= 821px) {
  h1 {
    font-size: 42px;
    line-height: 46px;
  }
}

h1 em {
  color: var(--jam-brand);
}

h2 {
  color: var(--jam-text-secondary);
  margin-bottom: 55px;
  font-size: 18px;
  font-weight: 500;
}

h2 strong {
  color: var(--jam-text-primary);
}

h3 {
  text-align: center;
  margin-bottom: 11px;
  font-size: 42px;
  font-weight: 600;
  line-height: 42px;
}

h4 {
  color: var(--jam-text-secondary);
  font-size: 18px;
  font-weight: 500;
}

h4 em {
  font-style: italic;
}

h4 strong {
  color: var(--jam-text-primary);
}

h5 {
  margin-bottom: 11px;
  font-size: 16px;
  font-weight: 700;
}

h6 {
  color: var(--jam-text-secondary);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
}

p {
  color: var(--jam-text-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

p strong {
  color: var(--jam-text-primary);
  font-style: italic;
}

input[type="email"] {
  border: 1px solid var(--jam-input-border);
  background: var(--jam-input-background);
  color: var(--jam-text-primary);
  border-radius: 9000px;
  width: 100%;
  max-width: 370px;
  margin-right: 6px;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
}

input[type="email"]:disabled {
  color: var(--jam-input-disabled);
}

.buttonWrap {
  margin: 22px 0;
}

button {
  background-color: var(--jam-positive);
  color: var(--jam-button-text);
  border: none;
  border-radius: 9000px;
  width: 100%;
  margin-top: 11px;
  padding: 16px 26px;
  font-size: 16px;
  font-weight: 700;
}

@media (width >= 821px) {
  button {
    width: auto;
    margin-top: 0;
    font-size: 14px;
    font-weight: 500;
  }
}

button.fat {
  width: 100%;
  padding: 16px 26px;
  font-size: 16px;
  font-weight: 700;
  line-height: 42px;
}

button.wide {
  padding: 16px 48px;
}

button:hover {
  background: var(--jam-positive-highlight);
  cursor: pointer;
}

button:disabled, button:disabled:hover {
  background: var(--jam-input-background);
  cursor: default;
  min-width: 120px;
}

button .loader {
  color: var(--jam-brand);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  animation: 1s ease-in infinite alternate fill;
  display: block;
  position: relative;
}

button .loader:before, button .loader:after {
  content: "";
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: .9s ease-in infinite alternate fill;
  position: absolute;
  top: 0;
  left: 24px;
}

button .loader:after {
  animation-duration: 1.1s;
  left: auto;
  right: 24px;
}

@keyframes fill {
  0% {
    box-shadow: inset 0 0 0 2px;
  }

  100% {
    box-shadow: inset 0 0 0 10px;
  }
}

@media (prefers-color-scheme: dark) {
  header .logo {
    text-indent: -9999px;
    background: url("logo-drk-mode.768745af.svg") center / 150px 32px no-repeat;
    width: 150px;
    height: 32px;
  }
}
/*# sourceMappingURL=index.7b8e4b69.css.map */
